.button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: absolute;
  bottom: 20%;
  left: 45%;
  @media (max-width: 1365px) {
    left: auto;
    right: 25%;
  }
  @media (max-width: 768px) {
    right: 15%;
    bottom: -25px;
  }
  img {
    width: 25px;
    height: auto;
  }
}
