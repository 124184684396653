@import "../../variables";

.word {
  display: inline-block;
  margin-right: 20px;
  &::first-letter {
    color: $primaryColor;
  }
}

.offer {
  padding-left: 12%;
  padding-right: 12%;
  &Text {
    display: block;
    padding: 40px 0;
    margin: 40px auto;
    border-top: 1px solid $primaryColor;
    border-bottom: 1px solid $primaryColor;
    font-weight: bold;
    letter-spacing: 3px;
    text-align: center;
    line-height: 1.8;
  }
  &Image {
    img {
      max-width: 100%;
      height: auto;
    }
  }
  &Col {
    margin: 50px auto;
    article {
      margin-bottom: 40px;
    }
    &:nth-child(3) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      .offerColArticlesWrapper {
        padding-right: 30px;
      }
      @media (max-width: 1600px) {
        grid-template-columns: 1fr;
        img {
          width: 100%;
        }
      }
    }
  }
}
