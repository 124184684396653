@import "../../variables";

.word {
  display: inline-block;
  margin-right: 20px;
  &::first-letter {
    color: $primaryColor;
  }
}

.about {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: 12%;
  padding-right: 12%;
  @media (max-width: 1170px) {
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  @media (max-width: 580px) {
    padding: 0 5%;
  }
  &Col {
    padding: 30px;
  }
  &Image {
    padding: 30px 0;
    img {
      max-width: 100%;
    }
  }
}

.additional {
  text-align: center;
  padding-left: 12%;
  padding-right: 12%;
  &__text {
    border-top: 1px solid rgba(white, .1);
    margin-top: 50px;
    padding-top: 50px;
    font-size: 20px;
    font-size: 2rem;
    font-weight: bold;
    font-style: italic;
    line-height: 1.618;
    margin-bottom: 50px;
  }
  &__cta {
    font-size: 24px;
    font-size: 2.4rem;
  }

  aside > p {
    font-size: 28px;
    font-size: 2.8rem;
    line-height: 1.618;
  }
}