@import "../../../../variables";

.menuToggle {
  display: none;
  @media (max-width: 1170px) {
    display: block;
    width: 50px;
    height: 50px;
    cursor: pointer;
    border: 0;
    background: 0;
    position: relative;
    margin-left: auto;
    &Line {
      display: block;
      position: absolute;
      width: 35px;
      height: 2px;
      left: calc(50% - 35px / 2);
      background-color: $primaryColor;
      &:nth-child(1) {
        top: 15px;
      }
      &:nth-child(2) {
        top: calc(50% - 2px / 2);
      }
      &:nth-child(3) {
        bottom: 15px;
      }
    }
  }
}
