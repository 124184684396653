@import "../../../variables";

.nav {
  display: flex;
  align-items: center;
}

.menu {
  display: flex;
  align-items: center;
  margin-left: auto;
  @media (max-width: 1170px) {
    display: block;
    margin: 0;
    width: 100%;
    max-width: 400px;
    background-color: $backgroundColor;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 998;
    transform: translateX(100%);
  }
  &Item {
    margin: 0 25px;
    @media (max-width: 1170px) {
      margin: 0;
    }
  }
  &Link {
    color: $textColor;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    letter-spacing: 2px;
    transition: color 200ms ease-in-out;
    @media (max-width: 1170px) {
      display: block;
      padding: 30px;
    }
    &:hover {
      color: $primaryColor;
    }
  }
}

.menuItem a.active {
  color: $primaryColor;
  // text-shadow: 0 0 7px rgba($primaryColor, 1);
}
