@import "../../variables";

.article {
  &Title {
    font-size: 36px;
    font-size: 3.6rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 25px;
    &::first-letter {
      color: $primaryColor;
    }
  }
  &Text {
    font-size: 20px;
    font-size: 2rem;
    font-weight: normal;
    line-height: 40px;
    line-height: 4rem;
  }
}
