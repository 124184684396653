@import "../../variables";

.form {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 2fr 1fr;
  width: 100%;
  height: 100%;
  padding-right: 30px;
  &Button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
