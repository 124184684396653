@import "../../variables";

.phone {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 24px;
  font-size: 2.4rem;
  transition: color 200ms ease-in-out;
  max-width: 250px;
  &:hover {
    color: $primaryColor;
  }
  &::before {
    display: inline-block;
    content: url(../../assets/img/phone-icon.svg);
    margin-right: 15px;
  }
  &Dark {
    color: $backgroundColor;
    &:hover {
      color: $backgroundColor;
      text-decoration: underline;
    }
    &::before {
      display: inline-block;
      content: url(../../assets/img/phone-icon-black.svg);
      margin-right: 15px;
    }
  }
}
