.header {
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  padding: 85px;
  padding-bottom: 55px;
  height: 200px;
  @media (max-width: 768px) {
    padding: 50px 25px;
    height: auto;
  }
}
