@import "../../variables";

.cta {
    text-align: center;
    margin: 40px auto;
  &Text {
    font-size: 48px;
    font-size: 4.8rem;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    margin-bottom: 25px;
  }
}
