@import "../../variables";

.form {
  &Row {
    position: relative;
    margin-bottom: 20px;
  }
  &Input {
    width: 100%;
    border: 0;
    background-color: transparent;
    border-bottom: 1px solid $primaryColor;
    padding: 10px 0;
    color: white;
    font-size: 20px;
    font-size: 2rem;
    font-family: "Play", sans-serif;
    &:hover {
      & + label {
        color: $primaryColor;
      }
    }
    &:focus {
      outline: 0;
      & + label {
        color: $primaryColor;
        text-shadow: 2px 2px 10px rgba($primaryColor, 0.85);
        transform: translate(0, -12px);
        font-size: 12px;
        font-size: 1.2rem;
      }
    }
  }
  &Textarea {
    @extend .formInput;
    height: 100%;
  }
  &Label {
    position: absolute;
    left: 0;
    top: 8px;
    font-size: 20px;
    font-size: 2rem;
    font-family: "Play", sans-serif;
    transition: color 0.2s ease-in-out, transform 0.2s ease-in-out,
      text-shadow 0.2s ease-in-out, font-size 0.2s ease-in-out;
    &Active {
      color: $primaryColor;
      text-shadow: 2px 2px 10px rgba($primaryColor, 0.85);
      transform: translate(0, -12px);
      font-size: 12px;
      font-size: 1.2rem;
    }
  }
}
