@import url("https://fonts.googleapis.com/css?family=Archivo+Black&display=swap&subset=latin-ext");
@import "../../variables";

@keyframes slideIn {
  from {
    transform: translateX(-100px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 0.35;
  }
}

.hero {
  width: 100%;
  max-width: 100%;
  min-height: calc(100vh - 200px);
  height: 600px;
  margin: auto;
  position: relative;
  padding-bottom: 100px;
  padding-left: 12%;
  padding-right: 12%;
  transition: opacity 0.5s ease-in-out;
  &::before {
    display: block;
    content: "";
    width: 65px;
    height: 85%;
    background: url(../../assets/img/some-shape.svg) no-repeat center;
    position: absolute;
    top: 0;
    right: 8%;
    opacity: 0.35;
    animation: slideIn 3s ease-in-out both;
  }
  &_button {
    position: absolute;
    bottom: 23%;
    left: 20%;
  }
}
.title {
  font-family: "Archivo", sans-serif;
  font-size: 120px;
  font-size: 12rem;
  font-weight: 900;
  text-transform: uppercase;
  position: absolute;
  top: 15%;
  left: 3%;
  max-width: 1000px;
  width: 100%;
  z-index: 1;
  @media (max-width: 1024px) {
    font-size: 90px;
    font-size: 9rem;
    top: 25%;
  }
  @media (max-width: 768px) {
    font-size: 64px;
    font-size: 6.4rem;
  }
}

.titleHome {
  @extend .title;
  left: auto;
  right: 0;
}

.imageWrapper {
  width: 100%;
  min-height: 100%;
  height: 600px;
  overflow: hidden;
  position: relative;
  &::after {
    display: block;
    content: "";
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $primaryColor;
  }
}

.image {
  // width: 100%;
  opacity: 0.65;
  @media (max-width: 1365px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.imageWrapperHome {
  @extend .imageWrapper;
}

.imageHome {
  @extend .image;
  position: absolute;
  right: 0;
  top: 0;
}
