@import "../../variables";

.button {
  display: inline-block;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: $textColor;
  text-decoration: none;
  padding: 20px 30px;
  cursor: pointer;
  border: 0;
  &::after {
    display: inline-block;
    content: url(../../assets/img/arrow-right-white.svg);
    margin-left: 10px;
    transition: transform 200ms ease-in-out;
  }
  &Primary {
    background-color: $primaryColor;
    color: $backgroundColor;
    transition: background-color 200ms ease-in-out, padding 200ms ease-in-out;
    &::after {
      content: url(../../assets/img/arrow-right-black.svg);
    }
    &:hover {
      background-color: lighten($primaryColor, 5%);
      padding: 20px 50px;
      &::after {
        transform: translateX(15px);
      }
    }
  }
}
