.googleMapContainer {
  height: 450px;
  margin: 0 auto;
  max-width: 1425px;
  width: 100%;
  position: relative;
  overflow: hidden;
  & > div {
    left: 0;
    top: 0;
  }
}
