@import "../../variables";

.footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 0 85px;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    text-align: center;
    justify-items: center;
    margin-top: 50px;
  }
}

.author {
  opacity: 0.5;
  text-transform: uppercase;
  font-size: 16px;
  font-size: 1.6rem;
  margin-left: auto;
  @media (max-width: 768px) {
    margin: 20px auto;
  }
  a {
    font-weight: bold;
    color: $textColor;
    text-decoration: none;
  }
}
