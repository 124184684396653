@import url(https://fonts.googleapis.com/css?family=Play:400,700&display=swap&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Archivo+Black&display=swap&subset=latin-ext);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)

  //// RESET ///
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: "";
  content: none; }

q:before, q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*,
*::before,
*::after {
  box-sizing: border-box; }

::-moz-selection {
  background-color: #fed766;
  color: #fff; }

::selection {
  background-color: #fed766;
  color: #fff; }

::-webkit-scrollbar {
  width: 10px; }

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px; }

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background: #fed766; }

html {
  font-size: 62.5%; }

body {
  font-family: "Play", sans-serif;
  font-size: 18px;
  font-size: 1.8rem;
  background-color: #0f1317;
  color: #fff; }

.HeaderNavigation_nav__1x7p7 {
  display: flex;
  align-items: center; }

.HeaderNavigation_menu__1lXZ7 {
  display: flex;
  align-items: center;
  margin-left: auto; }
  @media (max-width: 1170px) {
    .HeaderNavigation_menu__1lXZ7 {
      display: block;
      margin: 0;
      width: 100%;
      max-width: 400px;
      background-color: #0f1317;
      height: 100%;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 998;
      -webkit-transform: translateX(100%);
              transform: translateX(100%); } }
  .HeaderNavigation_menuItem__296v1 {
    margin: 0 25px; }
    @media (max-width: 1170px) {
      .HeaderNavigation_menuItem__296v1 {
        margin: 0; } }
  .HeaderNavigation_menuLink__3kJFg {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    letter-spacing: 2px;
    -webkit-transition: color 200ms ease-in-out;
    transition: color 200ms ease-in-out; }
    @media (max-width: 1170px) {
      .HeaderNavigation_menuLink__3kJFg {
        display: block;
        padding: 30px; } }
    .HeaderNavigation_menuLink__3kJFg:hover {
      color: #fed766; }

.HeaderNavigation_menuItem__296v1 a.HeaderNavigation_active__30_dm {
  color: #fed766; }

.Hamburger_menuToggle__1O3eF {
  display: none; }
  @media (max-width: 1170px) {
    .Hamburger_menuToggle__1O3eF {
      display: block;
      width: 50px;
      height: 50px;
      cursor: pointer;
      border: 0;
      background: 0;
      position: relative;
      margin-left: auto; }
      .Hamburger_menuToggleLine__2oB0e {
        display: block;
        position: absolute;
        width: 35px;
        height: 2px;
        left: calc(50% - 35px / 2);
        background-color: #fed766; }
        .Hamburger_menuToggleLine__2oB0e:nth-child(1) {
          top: 15px; }
        .Hamburger_menuToggleLine__2oB0e:nth-child(2) {
          top: calc(50% - 2px / 2); }
        .Hamburger_menuToggleLine__2oB0e:nth-child(3) {
          bottom: 15px; } }

.Branding_branding__3Q4Yz {
  width: 335px;
  height: auto; }

.Header_header__3Nxo8 {
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  padding: 85px;
  padding-bottom: 55px;
  height: 200px; }
  @media (max-width: 768px) {
    .Header_header__3Nxo8 {
      padding: 50px 25px;
      height: auto; } }

.Footer_footer__3Puw2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 0 85px;
  margin-bottom: 50px; }
  @media (max-width: 768px) {
    .Footer_footer__3Puw2 {
      grid-template-columns: 1fr;
      text-align: center;
      justify-items: center;
      margin-top: 50px; } }

.Footer_author__1gPe7 {
  opacity: 0.5;
  text-transform: uppercase;
  font-size: 16px;
  font-size: 1.6rem;
  margin-left: auto; }
  @media (max-width: 768px) {
    .Footer_author__1gPe7 {
      margin: 20px auto; } }
  .Footer_author__1gPe7 a {
    font-weight: bold;
    color: #fff;
    text-decoration: none; }

.Phone_phone__hYnPI {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 24px;
  font-size: 2.4rem;
  -webkit-transition: color 200ms ease-in-out;
  transition: color 200ms ease-in-out;
  max-width: 250px; }
  .Phone_phone__hYnPI:hover {
    color: #fed766; }
  .Phone_phone__hYnPI::before {
    display: inline-block;
    content: url(/static/media/phone-icon.05b561c7.svg);
    margin-right: 15px; }
  .Phone_phoneDark__3rcN8 {
    color: #0f1317; }
    .Phone_phoneDark__3rcN8:hover {
      color: #0f1317;
      text-decoration: underline; }
    .Phone_phoneDark__3rcN8::before {
      display: inline-block;
      content: url(/static/media/phone-icon-black.85a278a4.svg);
      margin-right: 15px; }

@-webkit-keyframes Hero_slideIn__152ul {
  from {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    opacity: 0; }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 0.35; } }
@keyframes Hero_slideIn__152ul {
  from {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    opacity: 0; }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 0.35; } }

.Hero_hero__dwz97 {
  width: 100%;
  max-width: 100%;
  min-height: calc(100vh - 200px);
  height: 600px;
  margin: auto;
  position: relative;
  padding-bottom: 100px;
  padding-left: 12%;
  padding-right: 12%;
  -webkit-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out; }
  .Hero_hero__dwz97::before {
    display: block;
    content: "";
    width: 65px;
    height: 85%;
    background: url(/static/media/some-shape.cde362ac.svg) no-repeat center;
    position: absolute;
    top: 0;
    right: 8%;
    opacity: 0.35;
    -webkit-animation: Hero_slideIn__152ul 3s ease-in-out both;
            animation: Hero_slideIn__152ul 3s ease-in-out both; }
  .Hero_hero_button__385EC {
    position: absolute;
    bottom: 23%;
    left: 20%; }

.Hero_title__2h4ra, .Hero_titleHome__27QLN {
  font-family: "Archivo", sans-serif;
  font-size: 120px;
  font-size: 12rem;
  font-weight: 900;
  text-transform: uppercase;
  position: absolute;
  top: 15%;
  left: 3%;
  max-width: 1000px;
  width: 100%;
  z-index: 1; }
  @media (max-width: 1024px) {
    .Hero_title__2h4ra, .Hero_titleHome__27QLN {
      font-size: 90px;
      font-size: 9rem;
      top: 25%; } }
  @media (max-width: 768px) {
    .Hero_title__2h4ra, .Hero_titleHome__27QLN {
      font-size: 64px;
      font-size: 6.4rem; } }

.Hero_titleHome__27QLN {
  left: auto;
  right: 0; }

.Hero_imageWrapper__2fCdc, .Hero_imageWrapperHome__2tyFI {
  width: 100%;
  min-height: 100%;
  height: 600px;
  overflow: hidden;
  position: relative; }
  .Hero_imageWrapper__2fCdc::after, .Hero_imageWrapperHome__2tyFI::after {
    display: block;
    content: "";
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fed766; }

.Hero_image__3teYW, .Hero_imageHome__3CXBP {
  opacity: 0.65; }
  @media (max-width: 1365px) {
    .Hero_image__3teYW, .Hero_imageHome__3CXBP {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); } }

.Hero_imageHome__3CXBP {
  position: absolute;
  right: 0;
  top: 0; }

.GoToContentButton_button__m0grk {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: absolute;
  bottom: 20%;
  left: 45%; }
  @media (max-width: 1365px) {
    .GoToContentButton_button__m0grk {
      left: auto;
      right: 25%; } }
  @media (max-width: 768px) {
    .GoToContentButton_button__m0grk {
      right: 15%;
      bottom: -25px; } }
  .GoToContentButton_button__m0grk img {
    width: 25px;
    height: auto; }

.Button_button__1dA8q {
  display: inline-block;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  padding: 20px 30px;
  cursor: pointer;
  border: 0; }
  .Button_button__1dA8q::after {
    display: inline-block;
    content: url(/static/media/arrow-right-white.a66f4181.svg);
    margin-left: 10px;
    -webkit-transition: -webkit-transform 200ms ease-in-out;
    transition: -webkit-transform 200ms ease-in-out;
    transition: transform 200ms ease-in-out;
    transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out; }
  .Button_buttonPrimary__ILupR {
    background-color: #fed766;
    color: #0f1317;
    -webkit-transition: background-color 200ms ease-in-out, padding 200ms ease-in-out;
    transition: background-color 200ms ease-in-out, padding 200ms ease-in-out; }
    .Button_buttonPrimary__ILupR::after {
      content: url(/static/media/arrow-right-black.9b9181cc.svg); }
    .Button_buttonPrimary__ILupR:hover {
      background-color: #fede7f;
      padding: 20px 50px; }
      .Button_buttonPrimary__ILupR:hover::after {
        -webkit-transform: translateX(15px);
                transform: translateX(15px); }

.StronaGlowna_word__2aLVr {
  display: inline-block;
  margin-right: 20px; }
  .StronaGlowna_word__2aLVr::first-letter {
    color: #fed766; }

.Article_articleTitle__2ywqI {
  font-size: 36px;
  font-size: 3.6rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 25px; }
  .Article_articleTitle__2ywqI::first-letter {
    color: #fed766; }

.Article_articleText__3Ifh1 {
  font-size: 20px;
  font-size: 2rem;
  font-weight: normal;
  line-height: 40px;
  line-height: 4rem; }

.Cta_cta__3_IrY {
  text-align: center;
  margin: 40px auto; }
  .Cta_ctaText__3FVO5 {
    font-size: 48px;
    font-size: 4.8rem;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    margin-bottom: 25px; }

.Omnie_word__3z1WD {
  display: inline-block;
  margin-right: 20px; }
  .Omnie_word__3z1WD::first-letter {
    color: #fed766; }

.Omnie_about__DOp9- {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: 12%;
  padding-right: 12%; }
  @media (max-width: 1170px) {
    .Omnie_about__DOp9- {
      grid-template-columns: 1fr;
      justify-content: center;
      align-items: center;
      text-align: center; } }
  @media (max-width: 580px) {
    .Omnie_about__DOp9- {
      padding: 0 5%; } }
  .Omnie_aboutCol__1XaWq {
    padding: 30px; }
  .Omnie_aboutImage__D8m9x {
    padding: 30px 0; }
    .Omnie_aboutImage__D8m9x img {
      max-width: 100%; }

.Omnie_additional__KRk5a {
  text-align: center;
  padding-left: 12%;
  padding-right: 12%; }
  .Omnie_additional__text__HtT1G {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 50px;
    padding-top: 50px;
    font-size: 20px;
    font-size: 2rem;
    font-weight: bold;
    font-style: italic;
    line-height: 1.618;
    margin-bottom: 50px; }
  .Omnie_additional__cta__CjVqv {
    font-size: 24px;
    font-size: 2.4rem; }
  .Omnie_additional__KRk5a aside > p {
    font-size: 28px;
    font-size: 2.8rem;
    line-height: 1.618; }

.Oferta_word___VbAz {
  display: inline-block;
  margin-right: 20px; }
  .Oferta_word___VbAz::first-letter {
    color: #fed766; }

.Oferta_offer__LSUSX {
  padding-left: 12%;
  padding-right: 12%; }
  .Oferta_offerText__3PSi4 {
    display: block;
    padding: 40px 0;
    margin: 40px auto;
    border-top: 1px solid #fed766;
    border-bottom: 1px solid #fed766;
    font-weight: bold;
    letter-spacing: 3px;
    text-align: center;
    line-height: 1.8; }
  .Oferta_offerImage__ElTxh img {
    max-width: 100%;
    height: auto; }
  .Oferta_offerCol__2FOMK {
    margin: 50px auto; }
    .Oferta_offerCol__2FOMK article {
      margin-bottom: 40px; }
    .Oferta_offerCol__2FOMK:nth-child(3) {
      display: grid;
      grid-template-columns: 1fr 1fr; }
      .Oferta_offerCol__2FOMK:nth-child(3) .Oferta_offerColArticlesWrapper__3zVZ6 {
        padding-right: 30px; }
      @media (max-width: 1600px) {
        .Oferta_offerCol__2FOMK:nth-child(3) {
          grid-template-columns: 1fr; }
          .Oferta_offerCol__2FOMK:nth-child(3) img {
            width: 100%; } }

.Kontakt_word__1aSRl {
  display: inline-block;
  margin-right: 20px; }
  .Kontakt_word__1aSRl::first-letter {
    color: #fed766; }

.Kontakt_contactWrapper__38ClQ {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;
  max-width: 1425px;
  margin: 0 auto;
  margin-bottom: 50px; }

.Kontakt_contactText__3Rs-T {
  font-size: 48px;
  font-size: 4.8rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  padding: 0 12%;
  margin: 50px auto; }
  .Kontakt_contactText__3Rs-T span {
    color: #fed766; }

.Kontakt_contactImage__syxRe {
  max-width: 100%;
  height: auto; }

.Form_form__2fEqR {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 2fr 1fr;
  width: 100%;
  height: 100%;
  padding-right: 30px; }
  .Form_formButton__1LP08 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; }

.Input_formRow__2Ifp2 {
  position: relative;
  margin-bottom: 20px; }

.Input_formInput__2UJbt, .Input_formTextarea__2M5pv {
  width: 100%;
  border: 0;
  background-color: transparent;
  border-bottom: 1px solid #fed766;
  padding: 10px 0;
  color: white;
  font-size: 20px;
  font-size: 2rem;
  font-family: "Play", sans-serif; }
  .Input_formInput__2UJbt:hover + label, .Input_formTextarea__2M5pv:hover + label {
    color: #fed766; }
  .Input_formInput__2UJbt:focus, .Input_formTextarea__2M5pv:focus {
    outline: 0; }
    .Input_formInput__2UJbt:focus + label, .Input_formTextarea__2M5pv:focus + label {
      color: #fed766;
      text-shadow: 2px 2px 10px rgba(254, 215, 102, 0.85);
      -webkit-transform: translate(0, -12px);
              transform: translate(0, -12px);
      font-size: 12px;
      font-size: 1.2rem; }

.Input_formTextarea__2M5pv {
  height: 100%; }

.Input_formLabel__1HtIz {
  position: absolute;
  left: 0;
  top: 8px;
  font-size: 20px;
  font-size: 2rem;
  font-family: "Play", sans-serif;
  -webkit-transition: color 0.2s ease-in-out, text-shadow 0.2s ease-in-out, font-size 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, text-shadow 0.2s ease-in-out, font-size 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, transform 0.2s ease-in-out, text-shadow 0.2s ease-in-out, font-size 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, transform 0.2s ease-in-out, text-shadow 0.2s ease-in-out, font-size 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
  .Input_formLabelActive__3sSZi {
    color: #fed766;
    text-shadow: 2px 2px 10px rgba(254, 215, 102, 0.85);
    -webkit-transform: translate(0, -12px);
            transform: translate(0, -12px);
    font-size: 12px;
    font-size: 1.2rem; }

.GoogleMap_googleMapContainer__qU7PS {
  height: 450px;
  margin: 0 auto;
  max-width: 1425px;
  width: 100%;
  position: relative;
  overflow: hidden; }
  .GoogleMap_googleMapContainer__qU7PS > div {
    left: 0;
    top: 0; }

.PhoneCta_phoneCta__3mFxQ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fed766;
  max-width: 1425px;
  margin: 30px auto;
  color: #0f1317;
  padding: 30px; }
  .PhoneCta_phoneCtaTitle__1gYPs {
    font-size: 36px;
    font-size: 3.6rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    width: 55%; }

