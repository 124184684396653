@import "../../variables";

.phoneCta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $primaryColor;
  max-width: 1425px;
  margin: 30px auto;
  color: $backgroundColor;
  padding: 30px;
  &Title {
    font-size: 36px;
    font-size: 3.6rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    width: 55%;
  }
}
