@import "../../variables";

.word {
  display: inline-block;
  margin-right: 20px;
  &::first-letter {
    color: $primaryColor;
  }
}

.contactWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;
  max-width: 1425px;
  margin: 0 auto;
  margin-bottom: 50px;
}

.contactText {
  font-size: 48px;
  font-size: 4.8rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  padding: 0 12%;
  margin: 50px auto;
  span {
    color: $primaryColor;
  }
}

.contactImage {
  max-width: 100%;
  height: auto;
}
